







.daily-balance-widget {
  font-weight: 700;
  .amount {
    font-size: 20px;
  }
}
